import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace user {
  // 获取登录二维码
  export function getLoginQrCode(params: {
    utm?: string
    kword?: string
    refererChannel?: string
    refererChannelDetail?: string
    browserType?: BrowserInfo['browser']
    inviteCode?: string
    bdVid?: string
    bdUrl?: string
  }): Promise<
    ApiBaseResult & {
      data: {
        url: string
        sceneId: string
        ticket: string
      }
    }
  > {
    return get('/auth/register_qr', { ...params, registerChannel: params.utm })
  }

  // 检查登录状态
  export function checkLoginStatus(
    sceneId: string
  ): Promise<ApiBaseResult & { data: { status: 'no_scan' | 'success' } }> {
    return get('/auth/register_qr/check', { sceneId })
  }

  export function logout() {
    return get('/user/logout')
  }

  export function getUserInfo(): Promise<
    ApiBaseResult & { data: ServerData.UserData }
  > {
    return get('/user/me')
  }

  export function getVipPlan(): Promise<
    ApiBaseResult & { data: ServerData.VipPlan[] }
  > {
    return get('/vip/plan')
  }

  export function orderCreate(
    data: PostData.order.Create
  ): Promise<ApiBaseResult & { data: { orderId: string; qr: string } }> {
    return post('/vip/order/create', {}, data)
  }

  export function getOrderStatus(orderId: string): Promise<
    ApiBaseResult & {
      data: {
        /** created: 待支付     completed: 已支付     canceled: 已取消     refunded: 已退款 */
        status: 'created' | 'completed' | 'canceled' | 'refunded'
      }
    }
  > {
    return get('/vip/order/status', { orderId })
  }

  export function active() {
    return post('/user/config/active')
  }
}
