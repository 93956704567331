import AmeModal from '../AmeModal/AmeModal'
import { observer } from 'mobx-react'
import styles from './AuthAccountModal.module.less'
import LoadingImg from '@/assets/loading.gif'
import AuthAccountIntroImg from '@/assets/auth-account-modal/auth-intro-img.png'
import classNames from 'classnames'
import { useAsync, useAsyncFn, } from 'react-use'
import { commonUtils } from '@/helpers/commonUtils'
import { api } from '@/helpers/api'
import { useContext, useEffect, useRef, } from 'react'
import { mpAccountContext } from '@/services/mpAccountService/mpAccountService'

export interface AuthAccountModalProps {
  open: boolean
  onSuccess?: (mp: { appid: string, avatar: string, nickname: string }) => void
  onClose?: () => void
}

// const usePooling = (callback: () => void, delay = 1000) => {
//   const ref = useRef<() => void>()
//   const idRef = useRef<NodeJS.Timeout>()
//   useEffect(() => {
//     ref.current = callback
//   }, [callback])

//   useEffect(() => {
//     idRef.current = setTimeout(() => {
//       callback()
//     })
//   }, [callback])
//   return {
//     start: () => { },
//     clear: () => { idRef.current && clearTimeout(idRef.current) }
//   }
// }


const AuthAccountModal = observer(({ open, onClose, onSuccess }: AuthAccountModalProps) => {
  const accountContext = useContext(mpAccountContext)
  const timeoutRef = useRef<NodeJS.Timeout>()

  const { value, loading } = useAsync(async () => {
    if (open) {
      const { qrUrl, uuid } = await api.mpAccount.authQr()
      return { qrUrl, uuid }
    }
  }, [open])
  const [checkState, check] = useAsyncFn(async () => {
    if (value) {
      const res = await api.mpAccount.checkAuthQrStatus(value!.uuid)
      return { ...res, uuid: value!.uuid }
    }
    return undefined
  }, [value])

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  })

  useEffect(() => {
    if (!open) {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  }, [open])

  useEffect(() => {
    // 首次轮询
    if (!loading && value) {
      check()
    }
  }, [loading, check, value])

  useEffect(() => {
    if (!checkState.loading && checkState.value && value) {
      if (checkState.value.auth && value.uuid === checkState.value.uuid) {
        accountContext.updateAccountList()
        onSuccess && onSuccess(checkState.value.wxMpAccount)
      } else {
        timeoutRef.current = setTimeout(() => {
          check()
        }, 1000)
      }
      if (!open) {
        timeoutRef && clearTimeout(timeoutRef.current)
      }
    }
  }, [checkState, check, open, accountContext, onSuccess, value])

  const onCancel = () => {
    onClose && onClose()
  }


  return open && <AmeModal open={open} title="公众平台授权" isCommonQrDialog footer={false} width={617} onCancel={onCancel}>
    <p className={classNames(styles.desc1)}>同步文章至公众号草稿箱，需先获得授权</p>
    <img className={classNames(styles.introImg)} src={AuthAccountIntroImg} alt="" />
    <div className={classNames(styles.qrBox)}>
      {loading && <img className={styles.loading} src={LoadingImg} alt="" />}
      {!loading && <img className={styles.qrCode} src={value?.qrUrl} alt="" />}
    </div>
    <p className={classNames(styles.desc2)}>使用公众号平台绑定的管理员个人微信号扫描</p>
    <div className={classNames(styles.introDescs)}>
      <p className={classNames(styles.desc3)}>管理员如何授权？</p>
      <p className={classNames(styles.desc4)}>1、如果管理员在身旁，直接扫描授权即可</p>
      <p className={classNames(styles.desc4)}>2、如果管理员在不身旁，可将二维码截图发送给管理员，在手机端长按扫码即可授权</p>
    </div>
  </AmeModal>
})

export default AuthAccountModal