import { EventEmitter, } from 'events'


/** 事件定义在此处 */
interface Events {
  /** 创建新文章 */
  createNewArticle: string,
  /** 更新文章 */
  updateArticle: { title: string, desc: string, content: string, id: string }
}

class LocalEventEmitter<E extends Events>  {
  private ee: EventEmitter
  constructor() {
    this.ee = new EventEmitter()
  }
  public emit<K extends keyof E>(eventName: K, args: E[K]): boolean {
    return this.ee.emit(eventName as string, args)
  }
  public on<K extends keyof E>(eventName: K, listener: (args: E[K]) => void) {
    return this.ee.on(eventName as string, listener)
  }
  public once<K extends keyof E>(eventName: K, listener: (args: E[K]) => void) {
    return this.ee.once(eventName as string, listener)
  }
  public off<K extends keyof E>(eventName: K, listener: (args: E[K]) => void) {
    return this.ee.off(eventName as string, listener)
  }
}

export const ee = new LocalEventEmitter()
