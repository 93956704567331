import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace aiTask {
  // 获取文章风格
  export function getArticleStyle() {
    return get('/article/style')
  }

  // 长文生成
  export function genLonger(
    data: PostData.AiTask.AiTaskGenLongerPostData
  ): Promise<{ data: { taskId: string } }> {
    return post('/gpt/longer/gen', {}, data)
  }

  export function getTaskStatus(taskId: string): Promise<
    ApiBaseResult & {
      data: {
        // 0 表示已完成 1 表示进行中 -1失败了
        status: 0 | 1 | -1
        // 错误原因
        error: string
      }
    }
  > {
    return get('/gpt/task/status', { taskId })
  }

  export function getTaskInfo(
    taskId: string
  ): Promise<ApiBaseResult & { data: ServerData.AiTaskInfo }> {
    return get('/gpt/task/info', { taskId })
  }

  export function edit(data: PostData.AiTask.EditData) {
    return post('/gpt/task/l_edit', {}, data)
  }

  export function doComment(data: { isLike: 1 | 2 | 0; contentId: string }) {
    return post('/gpt/content/comment', {}, data)
  }

  export function getHistoryList(data: {
    pageSize: number
    page: number
  }): Promise<ApiBaseResult & { data: ServerData.HistoryInfo[] }> {
    return get('/gpt/gen/history/list', data)
  }

  export function getStyle(): Promise<
    ApiBaseResult & { dataList: ServerData.AiStyle[] }
  > {
    return get('/article/style')
  }
}
