import { get, post } from './common'

export namespace styles {
  export function categoryList() {
    return get<{
      data: {
        tagList: Array<{
          // tags: Array<{ id: number, name: string, priority: number }>,
          title: string
        }>
      }
    }>('/article_editor/tag/list')
  }

  export function list(p: { page: number, pageSize: number, tagTitle?: string, tagId?: number, orderRule?: string }) {
    return get<{
      data: {
        materials: Array<{
          category: string,
          detail: string
          id: number
          type: string
          // tags: string[]
          // tagsId: number[]
        }>,
        totalCount: number
      }
    }>('/article_editor/style/list', p)
  }

  /** 是否能使用 */
  export function canUse() {
    return get<{
      data: {
        /** 0 是可以用， 1是不能用 */
        status: 0 | 1,
        count: number
      }
    }>('/article_editor/use/check')
  }

  /** 使用的时候调用一下次接口 */
  export function use(materialId: number) {
    return post('/article_editor/use', {}, { materialId, isCollection: false })
  }
}