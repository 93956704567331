import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './FormInput.module.less'

export interface FormInputProps {
  inputType?: 'small' | 'big' | 'number'
  placeholder?: string
  maxLength?: number
  min?: number
  max?: number
  value: string
  onChange: (value: string) => void
  onEnter?: (value: string) => void
  onBlur?: (value: string) => void
  autoFocus?: boolean
  enableAddBtn?: boolean
  disabled?: boolean
}

const FormInput: FC<FormInputProps> = observer((props: FormInputProps) => {
  const {
    inputType = 'small',
    placeholder,
    maxLength,
    value,
    onChange,
    onEnter,
    onBlur,
    autoFocus,
    enableAddBtn,
    min,
    max,
    disabled,
  } = props
  const [count, setWordCount] = useState(0)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (!autoFocus) {
      return
    }
    if (textareaRef && textareaRef.current) {
      textareaRef!.current!.focus()
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length
      )
    }
  }, [autoFocus])
  useEffect(() => {
    if (maxLength) {
      setWordCount(value ? value.length : 0)
    }
    if (inputRef.current && typeof value === 'string') {
      inputRef.current!.value = value
    }
  }, [value, maxLength])

  const resetValue = (value: string) => {
    inputRef.current!.value = value
  }

  const onValueChange: (value: string) => void = (_value) => {
    if (maxLength) {
      setWordCount(_value.length)
    }
    if (onChange) {
      onChange(_value)
    }
  }
  return (
    <div className={classNames(styles.formInput)}>
      {inputType === 'number' && (
        <div
          className={classNames(styles.inputBox, {
            [styles.inputDisable]: disabled,
          })}
        >
          <input
            ref={inputRef}
            className={classNames(styles.input)}
            type="number"
            min={min}
            max={max}
            maxLength={maxLength}
            disabled={disabled}
            value={value || ''}
            placeholder={placeholder}
            onBlur={() => {
              if (!value || isNaN(parseInt(value))) {
                if (onChange) {
                  onChange(min!.toString())
                }
                return
              }
              let _v = parseInt(value)
              if (_v < min!) {
                _v = min!
              } else if (_v > max!) {
                _v = max!
              }
              onChange!(_v.toString())
              resetValue(_v.toString())
            }}
            onChange={(e) => {
              let val = e.target.value
              if (maxLength && `${val}`.length > maxLength) {
                val = val.slice(0, maxLength) || ''
              }
              onChange(val)
            }}
          />
        </div>
      )}
      {inputType === 'small' && (
        <div
          className={classNames(styles.inputBox, {
            [styles.inputDisable]: disabled,
          })}
        >
          <input
            ref={inputRef}
            value={value}
            maxLength={maxLength}
            className={classNames(styles.input)}
            placeholder={placeholder}
            disabled={disabled}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && onEnter) {
                onEnter(value || '')
              }
            }}
            onChange={(e) => {
              onValueChange(e.target.value)
            }}
          />
          {maxLength && (
            <span className={classNames(styles.wordCount)}>
              {count}/{maxLength}
            </span>
          )}
          {enableAddBtn && <span className={classNames(styles.line)}></span>}
          {enableAddBtn && (
            <span
              className={classNames(styles.addBtn)}
              onClick={() => {
                onEnter!(value || '')
              }}
            >
              添加
            </span>
          )}
        </div>
      )}
      {inputType === 'big' && (
        <div className={classNames(styles.textareaBox)}>
          <textarea
            autoFocus={autoFocus}
            ref={textareaRef}
            value={value}
            maxLength={maxLength}
            className={classNames(styles.input, styles.multipleInput)}
            placeholder={placeholder}
            onChange={(e) => {
              onValueChange(e.target.value)
            }}
            onBlur={() => {
              if (onBlur) {
                onBlur(textareaRef.current?.value || '')
              }
            }}
          ></textarea>
          {maxLength && (
            <span className={classNames(styles.wordCount)}>
              {count}/{maxLength}
            </span>
          )}
        </div>
      )}
    </div>
  )
})

export default FormInput
