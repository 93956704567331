import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace template {
  export function list(moduleType?: string): Promise<
    ApiBaseResult & {
      data: {
        categories: ServerData.template.CategoryInfo[]
        modules: ServerData.template.TemplateInfo[]
      }
    }
  > {
    return get('/gpt/module/list', { source: 0, moduleType })
  }

  export function gen(
    data: PostData.TemplateTask.PostGenData
  ): Promise<ApiBaseResult & { data: { taskId: string } }> {
    return post('/gpt/module/gen', {}, data)
  }

  export function feedback(remark: string) {
    return post('/gpt/module/feedback', {}, { remark })
  }

  // 这里是管理后台接口，暂时放这里一下
  export function updateModule(data: {
    moduleId?: number
    moduleName: string
    moduleIcon?: string
    moduleType: string
    moduleDesc: string
    moduleInfo: {
      businessType: string
      detail: ServerData.template.TemplateConfig[]
    }
  }) {
    return post('/gpt/module/up', {}, data)
  }
}
