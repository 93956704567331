import { commonUtils } from '@/helpers/commonUtils';
import { KeyboardKey } from '@/helpers/hotKey';
import { OtherAttributeElement } from '@/typings/slateCustomTypes';

export const EXCLUDE_KEY_CODES = [
  KeyboardKey.SHIFT, KeyboardKey.ALT, KeyboardKey.META,
  KeyboardKey.CONTROL, KeyboardKey.CAPS_LOCK
]



export const MARK_BOLD = 'bold';
export const MARK_ITALIC = 'italic';
export const MARK_UNDERLINE = 'underline';

export const MARK_COLOR = 'color'
export const MARK_BG_COLOR = 'backgroundColor'
export const MARK_FONT_SIZE = 'fontSize'
export const MARK_LINE_HEIGHT = 'lineHeight'


export const IMAGE = 'image'
export const BLOCK_SECTION = 'section'
export const BLOCK_H1 = 'heading-one';
export const BLOCK_H2 = 'heading-two';
export const BLOCK_H3 = 'heading-three';
export const BLOCK_UL = 'bulleted-list'
export const BLOCK_OL = 'numbered-list'
export const BLOCK_LI = 'list-item'
export const BLOCK_PARAGRAPH = 'paragraph'

export const proxyImageSrc = (src: string | null) => {
  if (src === null) {
    return null
  }
  if (src.indexOf('http') === -1) {
    return src
  }
  if (src.indexOf('img_proxy') > -1) {
    return src
  } else {
    return `https://wximg.yiban.io/img_proxy?src=${src}`
  }
}

const genStyleAttributes = (el: HTMLElement): OtherAttributeElement => {
  const styles = el.getAttribute('style')
  const attributes: OtherAttributeElement = {}
  if (styles) {
    const attSplitStr = styles.split(';').filter(Boolean)
    if (attSplitStr) {
      const style = attSplitStr.reduce((pre, cur) => {
        const [key, ...value] = cur.split(':')
        let maybeUrl = ''
        if (value.length > 1) {
          const containUrlStr = value.join(':')
          maybeUrl = containUrlStr.replace('http', 'https://wximg.yiban.io/img_proxy?src=http').trim()
        } else {
          maybeUrl = value.join('').trim()
        }

        return {
          ...pre,
          [commonUtils.camelizeKey(key).trim()]: maybeUrl
        }
      }, {}) as any
      if (style['textAlign']) {
        attributes['align'] = style['textAlign']
      }
      attributes['style'] = style
    }
  }
  return attributes
}



export const ELEMENT_TAGS = {
  // A: (el: HTMLElement) => ({ type: 'link', url: el.getAttribute('href') }),
  // BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: (el: HTMLElement) => ({ type: BLOCK_H1, ...genStyleAttributes(el) }),
  H2: (el: HTMLElement) => ({ type: BLOCK_H2, ...genStyleAttributes(el) }),
  H3: (el: HTMLElement) => ({ type: BLOCK_H3, ...genStyleAttributes(el) }),
  // H4: () => ({ type: 'heading-four' }),
  // H5: () => ({ type: 'heading-five' }),
  // H6: () => ({ type: 'heading-six' }),
  IMG: (el: HTMLElement) => ({ type: IMAGE, src: proxyImageSrc(el.getAttribute('src')), ...genStyleAttributes(el) }),
  LI: (el: HTMLElement) => ({ type: BLOCK_LI, ...genStyleAttributes(el) }),
  OL: () => ({ type: BLOCK_OL }),
  UL: () => ({ type: BLOCK_UL }),
  P: (el: HTMLElement) => ({ type: BLOCK_PARAGRAPH, ...genStyleAttributes(el) }),
  SECTION: (el: HTMLElement) => ({ type: BLOCK_SECTION, ...genStyleAttributes(el), dataMpaTemplate: el.getAttribute('data-mpa-template') })
  // PRE: () => ({ type: 'code' }),
} as const

export const TEXT_TAGS = {
  // CODE: () => ({ code: true }),
  // DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
  SPAN: (el: HTMLElement) => {
    const attrStr = el.getAttribute('style')
    // console.log(attrStr)
    const attSplitStr = attrStr?.split(';').filter(Boolean)
    const style = attSplitStr?.reduce((pre, cur) => {
      const [key, ...value] = cur.split(':')
      return {
        ...pre,
        [commonUtils.camelizeKey(key).trim()]: value.join(':').trim()
      }
    }, {})
    return { style }
  }
} as const

