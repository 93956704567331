import { Button, ButtonProps } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { FC } from 'react'
import styles from './AmeButton.module.less'

interface AmeButtonProps extends ButtonProps {}

const AmeButton: FC<AmeButtonProps> = observer((props: AmeButtonProps) => {
  const { className, type, size } = props
  return (
    <Button
      {...props}
      className={classNames(className, styles.ameButton, {
        [styles.ameButtonLink]: type === 'link',
        [styles.ameButtonPrimary]: type === 'primary',
        [styles.ameButtonSmall]: size === 'small',
      })}
    >
      {props.children}
    </Button>
  )
})

export default AmeButton
