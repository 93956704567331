import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace article {
  export function list(data: {
    pageSize: number
    page: number
  }): Promise<
    ApiBaseResult & { data: ServerData.article.articleInfo[]; total: number }
  > {
    return get('/article_editor/article/list', data)
  }

  export function add(
    article: PostData.article.SavePostData
  ): Promise<ApiBaseResult & { data: ServerData.article.articleInfo }> {
    return post('/article_editor/article/save', {}, article)
  }

  export function update(data: {
    articleId: string
    article: PostData.article.SavePostData
  }): Promise<ApiBaseResult & { data: ServerData.article.articleInfo }> {
    return post(
      '/article_editor/article/update',
      { articleId: data.articleId },
      data.article
    )
  }

  export function one(
    articleId: string
  ): Promise<
    ApiBaseResult & {
      data: ServerData.article.articleInfo & { content: string }
    }
  > {
    return get('/article_editor/article/one', { articleId })
  }

  export function rename(articleId: string, title: string) {
    return post('/article_editor/article/rename', { articleId }, { title })
  }

  export function articleDelete(articleId: string) {
    return post('/article_editor/article/delete', { articleId })
  }
  export function articleShareCode(articleId: string) {
    return get<{ data: { code: string } }>('/article_editor/article/share', { articleId })
  }
  export function shareArticle(code: string) {
    return get<{
      data: ServerData.article.articleInfo & { content: string }
    }>('/article_editor/share/detail', { code })
  }
}
