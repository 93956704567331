import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import AmeModal from '../AmeModal/AmeModal'
import styles from './PayDialog.module.less'
import DefaultAvatar from '@/assets/avatar__default.png'
import { userService } from '@/services/userService'
import { dayjsHelper } from '@/helpers/dayjsHelper'
import LogoImg from '@/assets/logo__with-text.png'
import Icon1 from '@/assets/pay-dialog/icon___desc-1.png'
import Icon2 from '@/assets/pay-dialog/icon__desc-2.png'
import Icon3 from '@/assets/pay-dialog/icon__desc-3.png'
import Icon4 from '@/assets/pay-dialog/icon__desc-4.png'
import IconWechat from '@/assets/pay-dialog/icon__wechat.png'
import QrBg from '@/assets/pay-dialog/bg__qr.png'
import LeftBg from '@/assets/pay-dialog/bg__left.png'
import { api } from '@/helpers/api'
import { commonUtils } from '@/helpers/commonUtils'
import { uiController } from '@/controllers/uiController'
export interface PayDialogProps {
  open: boolean
  source?: string
  noFreeWords?: boolean
  selectedPlanId?: string
}

const DESC_LIST: Array<{ title: string; desc?: string; icon: string }> = [
  { title: '50，000字/月', desc: '每月充足的AI生成额度', icon: Icon1 },
  { title: '30+AI模版', desc: '多场景AI模版任您挑选', icon: Icon3 },
  { title: '长文写作', desc: '满足您所有的写作需求', icon: Icon2 },
  { title: 'AI自定义命令', desc: '帮您将创作流程化繁为简', icon: Icon4 },
]

const formatTime = (plan: ServerData.VipPlan) => {
  return `${plan.count}${plan.timeType === 'month' ? '个月' : '年'}`
}

const MAX_ERROR_COUNT = 3
let checkErrorCount = 0
let checkPayStatusTimeout: NodeJS.Timeout | null = null
let curOrderId: string | null = null

const PayDialog: FC<PayDialogProps> = observer(
  ({ open, noFreeWords, source, selectedPlanId }: PayDialogProps) => {
    const [vipPlan, setVipPlan] = useState<ServerData.VipPlan[]>([])
    const [selectedPlan, setSelectedVipPlan] = useState<ServerData.VipPlan>()
    const [qrUrl, setQrUrl] = useState('')
    const checkPayStatus = useCallback(async (orderId: string) => {
      try {
        const {
          data: { status },
        } = await api.user.getOrderStatus(orderId)
        if (curOrderId !== orderId) {
          return
        }
        if (status === 'created') {
          clearTimeout(checkPayStatusTimeout!)
          checkPayStatusTimeout = setTimeout(() => {
            checkPayStatus(orderId)
          }, 1 * 1000)
        } else if (status === 'completed') {
          uiController.payDialogProps = { open: false }
          uiController.showPaySuccessDialog = true
          userService.updateUserInfo()
        }
      } catch (error) {
        console.error('check pay status error', error)
        checkErrorCount += 1
        if (checkErrorCount < MAX_ERROR_COUNT) {
          clearTimeout(checkPayStatusTimeout!)
          checkPayStatusTimeout = setTimeout(() => {
            checkPayStatus(orderId)
          }, 1 * 1000)
        } else {
          uiController.message.error('支付状态检测失败，请稍后重试或联系客服')
        }
      }
    }, [])
    useEffect(() => {
      if (!selectedPlan || !open) {
        return
      }
      const getPayQrUrl = async () => {
        const {
          data: { orderId, qr },
        } = await api.user.orderCreate({
          planId: selectedPlan!.id,
          action:
            userService.userData?.config.version === 'free' ? 'buy' : 'renew',
          chargeChannel: 'wx_pub_qr',
          module: 'vip',
          source,
          version: 'pro',
        })
        clearTimeout(checkPayStatusTimeout!)
        curOrderId = orderId
        checkPayStatus(orderId)
        const _qr = await commonUtils.paseUrlToQrcode(qr, 120, 0)
        setQrUrl(_qr)
      }
      getPayQrUrl()
    }, [selectedPlan, checkPayStatus, source, open])
    useEffect(() => {
      if (!open) {
        return
      }
      const fetchVipPlanList = async () => {
        const { data } = await api.user.getVipPlan()
        const defaultSelectedPlan = data[1]
        if (selectedPlanId) {
          const selectedPlan = data.find((p) => p.id === selectedPlanId)
          setSelectedVipPlan(selectedPlan || defaultSelectedPlan)
        } else {
          setSelectedVipPlan(defaultSelectedPlan)
        }
        setVipPlan(data)
      }
      fetchVipPlanList()
    }, [open, selectedPlanId])
    if (!selectedPlan) {
      return null
    }
    return (
      <AmeModal
        className={styles.payDialog}
        footer={false}
        open={open}
        onCancel={() => {
          clearTimeout(checkPayStatusTimeout!)
          uiController.payDialogProps = { open: false }
        }}
      >
        <div className={classNames(styles.header)}>
          <img
            src={userService.userData?.wxAvatar || DefaultAvatar}
            alt=""
            className={styles.avatar}
          />
          <div>
            <span className={classNames(styles.nickname)}>
              {userService.userData?.wxNickname ||
                userService.userData?.inviteCode}
            </span>
            <span className={styles.expiredTime}>
              {userService.userData && userService.userData.config.gptExpireAt
                ? dayjsHelper.formatTime(
                  userService.userData!.config.gptExpireAt * 1000,
                  'YYYY-MM-DD HH:mm'
                )
                : '免费版'}
            </span>
          </div>
        </div>
        <div className={classNames(styles.box)}>
          <div className={classNames(styles.left)}>
            <img src={LeftBg} alt="" className={classNames(styles.leftBg)} />
            <div>
              <img src={LogoImg} alt="" className={classNames(styles.logo)} />
              {DESC_LIST.map((item, i) => {
                return (
                  <div key={i} className={classNames(styles.item)}>
                    <p>
                      <img src={item.icon} alt="" /> {item.title}
                    </p>
                    {item.desc && (
                      <p className={classNames(styles.desc)}>{item.desc}</p>
                    )}
                  </div>
                )
              })}
            </div>
          </div>

          <div className={classNames(styles.right)}>
            <div className={classNames(styles.planList)}>
              {vipPlan.map((plan) => {
                return (
                  <div
                    key={plan.id}
                    className={classNames(styles.plan, {
                      [styles.active]: selectedPlan!.id === plan.id,
                    })}
                    onClick={() => {
                      setSelectedVipPlan(plan)
                    }}
                  >
                    {plan.timeType === 'year' && (
                      <div className={classNames(styles.tips)}>超值特惠</div>
                    )}
                    <p className={classNames(styles.planTime)}>
                      {formatTime(plan)}
                    </p>
                    <p className={classNames(styles.priceBox)}>
                      <span>¥</span>
                      {Math.floor(plan.price / 100)}
                    </p>
                    <p className={classNames(styles.unitPrice)}>
                      低至¥ {plan.unitDesc}
                    </p>
                  </div>
                )
              })}
            </div>
            <div className={classNames(styles.orderBox)}>
              <p className={classNames(styles.title)}>
                <img
                  src={IconWechat}
                  alt=""
                  className={classNames(styles.icon)}
                />
                微信支付
              </p>
              <div className={classNames(styles.order)}>
                <div className={classNames(styles.imgBox)}>
                  <img src={QrBg} alt="" className={classNames(styles.qrBg)} />
                  <img src={qrUrl} alt="" className={classNames(styles.qr)} />
                </div>
                <div style={{ flex: '1' }}>
                  <div className={classNames(styles.payChannel)}>确认订单</div>
                  <p className={styles.rowTwo}>
                    <span className={classNames(styles.label)}>购买版本：</span>
                    <span>VIP会员-{formatTime(selectedPlan!)}</span>
                  </p>
                  <p className={styles.rowTwo}>
                    <span className={classNames(styles.label)}>购买金额：</span>
                    <span>{Math.floor(selectedPlan!.price / 100)}元</span>
                  </p>
                  <p className={classNames(styles.price)}>
                    ¥{Math.floor(selectedPlan!.price / 100)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AmeModal>
    )
  }
)

export default PayDialog
