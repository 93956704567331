import { AmeModalProps } from '@/components/AmeModal'
import { AuthAccountEmptyModalProps } from '@/components/AuthAccountEmptyModal/AuthAccountEmptyModal'
import { AuthAccountModalProps } from '@/components/AuthAccountModal/AuthAccountModal'
import { PayDialogProps } from '@/components/PayDialog'
import { SyncArticleModalProps } from '@/components/SyncArticleModal/SyncArticleModal'
import { MessageInstance } from 'antd/es/message/interface'
import { makeObservable, observable } from 'mobx'

class UiController {
  public message!: MessageInstance
  @observable showLoginModal = false
  @observable commonModalProps: AmeModalProps = { open: false }
  @observable payDialogProps: PayDialogProps = {
    open: false,
  }
  @observable showPaySuccessDialog = false
  @observable showContactUsDialog = false
  @observable showNoEnoughTokenDialog = false
  @observable showFreeWordDialog = false
  /** 授权公众号的弹窗 */
  @observable showAuthAccountModalProps: AuthAccountModalProps = { open: false }
  @observable emptyAccountGuideModalProps: AuthAccountEmptyModalProps = { open: false, article: null }
  /** 同步文章的弹窗 */
  @observable syncArticleModalProps: SyncArticleModalProps = { open: false, article: null }
  @observable showSyncArticleSuccessModal = false
  /** 分享文章的复制链接弹窗 */
  @observable shareArticleLinkModalProps = { open: false, link: '' }
  public init() {
    makeObservable(this)
  }
}

export const uiController = new UiController()
