import { api } from '@/helpers/api';
import { action, makeObservable, observable } from 'mobx';
import { createContext } from 'react';


interface Mp {

  appid: string, nickname: string, id: string
  avatar: string, authorized: boolean
}
class MpAccountService {
  @observable mpList: Mp[] = []

  constructor() {
    makeObservable(this)
  }

  @action changeMpList(mpList: Mp[]) {
    this.mpList = mpList
  }

  public async updateAccountList() {
    try {
      const { data: mpList } = await api.mpAccount.list()
      this.changeMpList(mpList)
    } catch (error) {
      // do noting
    }

  }

}


export const mpAccountService = new MpAccountService()

export const mpAccountContext = createContext(mpAccountService)