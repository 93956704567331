
// export const COMMANDER_HOT_KEY = '/'

export enum KeyboardKey {
  COMMANDER_HOT_KEY = '/',
  ENTER = 'Enter',
  BACKSPACE = 'Backspace',
  SHIFT = 'Shift',
  ALT = 'Alt',
  META = 'Meta',
  CONTROL = 'Control',
  CAPS_LOCK = 'CapsLock',
  ESC = 'Escape',
  SPACE = ' '
}