export const PAGE_LINKS = {
  home: '/',
  login: '/login',
  editor: '/editor',
  share: '/share',
  price: '/price'
}

export const KUAIXIE_TOOLBAR = 'kuaixie-toolbar'
export const KUAIXIE_EDITOR_WRAPPER = 'kuaixie-editor-wrapper'
export const KUAIXIE_EDITOR_BOX = 'kuaixie-editor-box'
export const KUAIXIE_EDITOR_BOX_INNER = 'kuaixie-editor-box-inner'
export const KUAIXIE_EDITOR = 'kuaixie-editor'
export const KUAIXIE_EDITOR_PLACEHOLDRE_BOX = 'kuaixie-editor-placeholder-box'
export const KUAIXIE_COMMANDER_GENERATE_BOX_WRAPPER =
  'kuaixie-commander-generate-box-wrapper'


export const ICONFONT_SCRIPT_URL = '//at.alicdn.com/t/c/font_4293805_20ia40ad732.js'


export const LONGER_GEN_BUSINESS_TYPE: PostData.AiTask.AiTaskGenLongerPostData['businessType'][] = [
  'longer_desc_to_keyword', 'longer_expend_keyword_to_part', 'longer_expend_part_point_to_content',
  'longer_keyword_to_part', 'longer_more_keyword_to_part', 'longer_part_point_to_content', 'longer_replace_keyword_to_part',
  'longer_replace_part_point_to_content',
]
export const TEMPLATE_LIST = [
  {
    moduleDesc: '让你的日常琐事与重复工作，显得更有价值',
    moduleName: '工作日报',
    moduleType: 'common',
    moduleId: 10,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_gongzuoribao.ec53ea7bea65.png',
    moduleInfo: {
      businessType: 'module_work_daily_gen',
      detail: [
        {
          label: '今日工作内容',
          key: 'today',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder: '维护了10个微信群、、打了10通电话、签约了3个客户',
        },
        {
          label: '明日工作计划',
          key: 'content',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder: '维系客户、撰写邮件、梳理工作流程',
        },
        {
          label: '岗位名称',
          key: 'job',
          required: false,
          type: 'input',
          max_length: 50,
          placeholder: '销售、行政',
        },
      ],
    },
  },
  {
    moduleDesc: '快速帮你生成内容丰富、条理清楚的周报内容',
    moduleName: '工作周报',
    moduleType: 'common',
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_gongzuozhoubao.440560662879.png',
    moduleId: 1,
    moduleInfo: {
      businessType: 'module_work_weekly_gen',
      detail: [
        {
          label: '本周工作重点',
          key: 'point',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder: '挑选最重要的一条内容，例如：微信群的直播导流',
        },
        {
          label: '本周工作内容',
          key: 'content',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder:
            '内容建议包含量化结果，例如：维护了10个微信群、、打了10通电话、签约了3个客户',
        },
        {
          label: '下周工作计划',
          key: 'plan',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder: '例如：维系客户、撰写邮件、梳理工作流程',
        },
        {
          label: '岗位名称',
          key: 'job',
          required: false,
          type: 'input',
          max_length: 50,
          placeholder: '请输入您的岗位名称，例如：销售、行政',
        },
      ],
    },
  },
  {
    moduleDesc: 'AI帮你秒变专业HR，生成可直接在招聘网站上发布的JD内容',
    moduleName: '岗位介绍',
    moduleType: 'common',
    moduleId: 11,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_gangweijieshao.a9fda4a743fd.png',
    moduleInfo: {
      businessType: 'module_job_ntroduction_gen',
      detail: [
        {
          label: '岗位名称',
          key: 'job',
          required: true,
          type: 'input',
          max_length: 50,
          placeholder: '高级销售经理',
        },
        {
          label: '岗位职责',
          key: 'duty',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder:
            '1.负责华南地区大客户陌拜、扩展和线索转化；2.与公司其他团队配合，推动项目',
        },
        {
          label: '岗位要求',
          key: 'require',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder: '1.3年销售经验；2.本科学历以上',
        },
      ],
    },
  },
  {
    moduleDesc: '根据工作内容，快速生成总结提高工作效率。',
    moduleName: '工作总结',
    moduleType: 'common',
    moduleId: 12,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_gongzuozongjie.d7f4fddf251e.png',
    moduleInfo: {
      businessType: 'module_work_summary_gen',
      detail: [
        {
          label: '岗位名称',
          key: 'job',
          required: true,
          type: 'input',
          max_length: 50,
          placeholder: '产品经理',
        },
        {
          label: '时间周期',
          key: 'period',
          required: true,
          type: 'select',
          placeholder: '季度',
          options: ['月度', '季度', '年度'],
        },
        {
          label: '工作内容',
          key: 'content',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder: '市场分析、用户需求分析、竟品分析等',
        },
      ],
    },
  },

  {
    moduleDesc: '根据目标关键词和具体的职位，制定更加科学、全面的工作计划',
    moduleName: '工作计划',
    moduleType: 'common',
    moduleId: 13,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_gongzuojihua.afcc6c240d07.png',
    moduleInfo: {
      businessType: 'module_work_plan_gen',
      detail: [
        {
          label: '职位',
          key: 'job',
          required: true,
          type: 'input',
          max_length: 50,
          placeholder: '软件测试工程师',
        },
        {
          label: '时间周期',
          key: 'period',
          required: true,
          type: 'select',
          placeholder: '季度',
          options: ['月度', '季度', '年度'],
        },
        {
          label: '目标关键词',
          key: 'point',
          required: true,
          type: 'input',
          max_length: 50,
          placeholder:
            '完成测试用例管理后台及至少三个通用的重要业务自动测试脚本',
        },
      ],
    },
  },
  {
    moduleName: '段落',
    moduleDesc: '生成富有连贯性和可读性的文章段落，提供高质量的创作支持',
    moduleType: 'literature',
    moduleId: 14,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_duanluo.f75aece7e037.png',
    moduleInfo: {
      businessType: 'module_paragraph_gen',
      detail: [
        {
          label: '段落主题',
          key: 'theme',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder:
            '我需要一篇小学生作文，300字左右，作文主题是乌鸦喝水的故事',
        },
      ],
    },
  },
  {
    moduleName: '大纲',
    moduleDesc:
      '根据描述的文章主题和描述，智能生成文章大纲，轻松完成文章框架和思路',
    moduleType: 'literature',
    moduleId: 15,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_dagang.f86970440f9d.png',
    moduleInfo: {
      businessType: 'module_outline_gen',
      detail: [
        {
          label: '主题',
          key: 'theme',
          required: true,
          type: 'input',
          max_length: 50,
          placeholder: '岁月如梭',
        },
      ],
    },
  },
  {
    moduleName: '标题',
    moduleDesc: '生成更具吸引力的标题，帮助提高文章的点击率和传播效果',
    moduleType: 'literature',
    moduleId: 16,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_biaoti.a230f6470bad.png',
    moduleInfo: {
      businessType: 'module_title_gen',
      detail: [
        {
          label: '文章主题',
          key: 'theme',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder: '我现在要写一篇旅游推文，帮我生成标题',
        },
      ],
    },
  },
  {
    moduleName: '诗词',
    moduleDesc: '根据描述的主题，生成不同类型的诗词',
    moduleType: 'literature',
    moduleId: 17,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_shici.7f110dc0a7ac.png',
    moduleInfo: {
      businessType: 'module_poetry_gen',
      detail: [
        {
          label: '分类',
          key: 'classify',
          required: true,
          type: 'select',
          placeholder: '散文诗',
          options: ['散文诗', '藏头诗', '现代诗'],
        },
        {
          label: '描述',
          key: 'desc',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder: '赞美江南春色',
        },
      ],
    },
  },
  {
    moduleName: '短篇故事',
    moduleDesc: '根据描述的关键词，并选择不同的题材，自动生成完整的故事。',
    moduleType: 'literature',
    moduleId: 18,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_duanpiangushi.12c0d33ccea5.png',
    moduleInfo: {
      businessType: 'module_short_story_gen',
      detail: [
        {
          label: '关键词',
          key: 'keyword',
          required: true,
          type: 'input',
          max_length: 50,
          placeholder: '外星人入侵、核弹、流亡',
        },
        {
          label: '题材',
          key: 'classify',
          required: true,
          type: 'select',
          placeholder: '科幻',
          options: ['科幻', '冒险', '童话', '神话', '恐怖'],
        },
      ],
    },
  },
  {
    moduleName: '论文大纲',
    moduleDesc:
      '根据研究主题和对象生成论文大纲，确保论文的完整性、准确性，提高论文质量。',
    moduleType: 'education',
    moduleId: 19,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_lunwendagang.f3fe44aa7f9f.png',
    moduleInfo: {
      businessType: 'module_essay_outline_gen',
      detail: [
        {
          label: '研究对象',
          key: 'object',
          required: true,
          type: 'input',
          max_length: 50,
          placeholder: '人工智能与医疗',
        },
        {
          label: '研究主题',
          key: 'theme',
          required: true,
          type: 'input',
          max_length: 50,
          placeholder: '人工智能技术在医疗领域的应用研究',
        },
      ],
    },
  },

  {
    moduleName: '实习报告',
    moduleDesc: '根据角色和岗位，生成标准化实习报告',
    moduleType: 'education',
    moduleId: 20,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_shixibaogao.37f823435e39.png',
    moduleInfo: {
      businessType: 'module_internship_report_gen',
      detail: [
        {
          label: '岗位名称',
          key: 'job',
          required: true,
          type: 'input',
          max_length: 50,
          placeholder: '产品经理',
        },
        {
          label: '描述',
          key: 'desc',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder: '用户调研和竞品分析',
        },
      ],
    },
  },
  {
    moduleName: '心得体会',
    moduleDesc:
      '根据描述的主题，生成心得体会，为用户提供更多的思路和想法，扩展思路宽度',
    moduleType: 'education',
    moduleId: 21,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_xindetihui.a0efd25149a0.png',
    moduleInfo: {
      businessType: 'module_experience_gen',
      detail: [
        {
          label: '主题',
          key: 'theme',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder: '参观西河头地道站遗址',
        },
        {
          label: '语气',
          key: 'tone',
          required: true,
          type: 'select',
          placeholder: '有说服力的',
          options: ['有说服力的', '专业', '幽默', '正式'],
        },
      ],
    },
  },
  {
    moduleName: '大众点评',
    moduleDesc: '根据描述，快速生成准确且优质的点评，减轻人工点评的负担',
    moduleType: 'social_media',
    moduleId: 22,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_dazongdianping.4abaff6ec924.png',
    moduleInfo: {
      businessType: 'module_comment_gen',
      detail: [
        {
          label: '名称',
          key: 'name',
          required: true,
          type: 'input',
          max_length: 50,
          placeholder: '保温杯',
        },
        {
          label: '描述',
          key: 'theme',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder: '保温效果好、耐摔',
        },
      ],
    },
  },
  {
    moduleName: '旅游攻略',
    moduleDesc: '根据目的地和描述生成内容丰富的旅游攻略',
    moduleType: 'social_media',
    moduleId: 23,
    moduleIcon:
      'https://assets.weibanzhushou.com/web/kx-web/kuaixie_lvyougonglue.88ccec2e47f0.png',
    moduleInfo: {
      businessType: 'module_travelling_guideline_gen',
      detail: [
        {
          label: '目的地',
          key: 'name',
          required: true,
          type: 'input',
          max_length: 50,
          placeholder: '重庆',
        },
        {
          label: '描述',
          key: 'theme',
          required: true,
          type: 'textarea',
          max_length: 300,
          placeholder: '旅行时间7天',
        },
      ],
    },
  },
]

export const DEFAULT_MODULE = {
  moduleDesc: '不知道该用哪个模版？万能模版都能写！',
  moduleName: '万能模版',
  moduleType: 'common',
  // moduleId: 1,
  moduleIcon:
    'https://assets.weibanzhushou.com/web/kx-web/zidingyinew.fd1a413c1abc.png',
  moduleInfo: {
    businessType: 'module_default_gen',
    detail: [
      {
        label: '描述',
        key: 'desc',
        required: true,
        type: 'textarea',
        max_length: 300,
        placeholder: '请写一篇关于研究雾霾天气的PPT内容 ',
      },
    ],
  },
}
