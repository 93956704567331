import { useMount } from 'react-use'
import styles from './AuthAccountEmptyModal.module.less'
import { observer } from 'mobx-react'
import AmeModal from '../AmeModal/AmeModal'
import emptyImg from '@/assets/auth-account-modal/empty-img.png'
import dangIcon from '@/assets/auth-account-modal/dang-icon.png'
import AmeButton from '../AmeButton/AmeButton'
import { uiController } from '@/controllers/uiController'
import { message } from 'antd'
import { useContext } from 'react'
import { mpAccountContext } from '@/services/mpAccountService/mpAccountService'

export interface AuthAccountEmptyModalProps {
  open: boolean
  article: { title: string, desc: string, content: string } | null
}

const AuthAccountEmptyModal = observer(({ open, article }: AuthAccountEmptyModalProps) => {
  const accountContext = useContext(mpAccountContext)
  useMount(() => {
    if (!open) { return }
  })

  return <AmeModal open={open} title="多平台同步" isCommonQrDialog width={756} footer={false} onCancel={() => {
    uiController.emptyAccountGuideModalProps = { open: false, article: null }
  }}>
    <div className={styles.desc1}>
      <img src={dangIcon} alt="" />
      <span>可将文章内容一键同步至其他平台进行发布（目前支持微信公众平台）</span>
    </div>
    <img className={styles.emptyImg} src={emptyImg} alt="" />
    <p className={styles.desc2}>暂无授权公众号～</p>
    <p className={styles.desc2}>授权后可将文章自动同步至公众号草稿箱</p>
    <AmeButton className={styles.authBtn} type="primary" onClick={() => {
      uiController.emptyAccountGuideModalProps = { open: false, article: null }
      uiController.showAuthAccountModalProps = {
        open: true,
        onClose: () => {
          uiController.showAuthAccountModalProps = { open: false }
        },
        onSuccess: (mp) => {
          uiController.showAuthAccountModalProps = { open: false }
          message.success('授权成功')
          accountContext.updateAccountList()
          uiController.syncArticleModalProps = {
            open: true, mp, article
          }
        }
      }
    }}>授权公众号</AmeButton>
  </AmeModal>
})


export default AuthAccountEmptyModal