import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC } from 'react'
import AmeModal from '../AmeModal/AmeModal'
import styles from './PaySuccessDialog.module.less'
import Bg from '@/assets/pay-dialog/bg__pay-success.png'
import AmeButton from '../AmeButton/AmeButton'
import { uiController } from '@/controllers/uiController'

interface PaySuccessDialogProps {
  open: boolean
}

const PaySuccessDialog: FC<PaySuccessDialogProps> = observer(
  (props: PaySuccessDialogProps) => {
    return (
      <AmeModal
        open={props.open}
        className={classNames(styles.paySuccessDialog)}
        footer={false}
        onCancel={() => {
          uiController.showPaySuccessDialog = false
        }}
      >
        <div className={classNames(styles.content)}>
          <p className={classNames(styles.title)}>付费成功</p>
          <img src={Bg} alt="" className={classNames(styles.bg)} />
          <AmeButton
            type="primary"
            size="large"
            onClick={() => {
              uiController.showPaySuccessDialog = false
            }}
          >
            关闭
          </AmeButton>
        </div>
      </AmeModal>
    )
  }
)

export default PaySuccessDialog
