import { useCopyToClipboard, useMount } from 'react-use'
import AmeModal from '../AmeModal/AmeModal'
import styles from './ShareArticleLinkModal.module.less'
import ModalBg from '@/assets/share-article-modal/share-article-modal-bg.png'
import { uiController } from '@/controllers/uiController'
import AmeButton from '../AmeButton/AmeButton'


interface Props {
  link: string
  open: boolean
}


const ShareArticleLinkModal = ({ link, open }: Props) => {
  const [state, copy] = useCopyToClipboard()
  useMount(() => {
    if (!link || !open) { return }
  })
  const onCancel = () => {
    uiController.shareArticleLinkModalProps = { open: false, link: '' }
  }

  const handleCopy = () => {
    copy(link)
    state.error
      ? uiController.message.error('复制失败，请联系客服')
      : uiController.message.success('分享链接已复制~')
  }

  return <AmeModal width={612} open={open} footer={false} className={styles.shareArticleLinkModal} onCancel={onCancel}>
    <div className={styles.bg}>
      <img src={ModalBg} alt="" />
    </div>
    <div className={styles.shareArticleLinkModalContent}>
      <p className={styles.title}>分享</p>
      <p className={styles.desc}>复制链接发送给好友阅读</p>
      <div className={styles.copyBox}>
        <div className={styles.link}>{link}</div>
        <AmeButton type="primary" className={styles.copyBtn} onClick={handleCopy}>复制</AmeButton>
      </div>
    </div>
  </AmeModal>
}


export default ShareArticleLinkModal