import { ElementType, Suspense, lazy } from 'react'
import { RouteObject, useRoutes } from 'react-router-dom'
import AmeSpin from './components/AmeSpin/AmeSpin'
import { PAGE_LINKS } from './helpers/commonConsts'

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<AmeSpin fullscreen={true} />}>
      <Component {...props} />
    </Suspense>
  )

const HomePage = Loadable(lazy(() => import('./pages/HomePage/HomePage')))
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage/LoginPage')))
const EditorPage = Loadable(lazy(() => import('./pages/EditorPage/EditorPage')))
const SharePage = Loadable(lazy(() => import('./pages/SharePage/SharePage')))
const PricePage = Loadable(lazy(() => import('./pages/PricePage/PricePage')))
const routes: RouteObject[] = [
  {
    path: PAGE_LINKS.home,
    element: <HomePage />,
  },
  {
    path: PAGE_LINKS.login,
    element: <LoginPage />,
  },
  {
    path: PAGE_LINKS.editor,
    element: <EditorPage />,
  },
  {
    path: PAGE_LINKS.share,
    element: <SharePage />,
  },
  {
    path: PAGE_LINKS.price,
    element: <PricePage />,
  },
]

export const Router = () => {
  return useRoutes(routes)
}
