import { get, post, } from './common'

export namespace mpAccount {
  export function authQr() {
    return get<{ qrUrl: string, uuid: string }>('/mp_auth/auth_qr')
  }
  export function checkAuthQrStatus(uuid: string) {
    return get<{
      auth: boolean, wxMpAccount: {
        appid: string, nickname: string, avatar: string,
        id: string, authorized: boolean
      }
    }>('/mp_auth/check_auth_qr', { uuid })
  }
  export function list() {
    return get<{
      data: Array<{
        appid: string, nickname: string, avatar: string,
        id: string, authorized: boolean
      }>
    }>('/wx_component/list')
  }
  export function del(appid: string) {
    return get('/wx_component/delete', { appid })
  }

  export function syncArticleToWxDraft(param: {
    appid: string,
    title: string,
    content: string
    thumbMediaContent: string
    filename: string
    author?: string
    digest?: string
    // content_source_url?: string
    // need_open_comment?: number
    // only_fans_can_comment?: number
  }) {
    return post('/wx_component/add_draft', {}, param)
  }
}