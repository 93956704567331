import { CustomElement } from '@/typings/slateCustomTypes'
import { ELEMENT_TAGS, TEXT_TAGS } from '../type'
import { jsx } from 'slate-hyperscript'

export const deserialize = (el: Node) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return el.textContent
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return ''
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const { nodeName } = el
  let parent = el as ChildNode

  if (
    nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0]
  }
  let children = Array.from(parent.childNodes).map(deserialize).flat() as any
  const isListParent = parent.nodeName === 'UL' || parent.nodeName === 'OL'
  if (isListParent) {
    children = children.filter((c: CustomElement) => c.type)
  }

  if (children.length === 0) {
    children = [{ text: '' }]
  }
  if (el.nodeName === 'BODY') {
    const result = jsx('fragment', {}, children)
    return result
  }
  // console.log('nodeName', nodeName)
  const elementTagHandler = ELEMENT_TAGS[nodeName as keyof typeof ELEMENT_TAGS]
  // console.log('element', nodeName, elementTagHandler)
  if (elementTagHandler) {
    const attrs = elementTagHandler(el as HTMLElement)
    return jsx('element', attrs, children)
  }
  const textTagHandler = TEXT_TAGS[nodeName as keyof typeof TEXT_TAGS]
  if (textTagHandler) {
    const attrs = textTagHandler(el as HTMLElement)
    return children.map((child: any) => {
      const res = jsx('text', attrs, child)
      // console.log('res', res)
      return res
    })
  }
  return children
}



