import { useMount, useToggle, } from 'react-use'
import AmeModal from '../AmeModal/AmeModal'
import styles from './SyncArticleModal.module.less'
// import FormBox from '../Form/FormBox'
// import FormRowItem from '../FormRowItem/FormRowItem'
import FormInput from '../FormInput/FormInput'
import Upload from 'antd/es/upload/Upload'
import React, { useContext, useEffect, useRef, useState } from 'react'
import DangIcon from '@/assets/auth-account-modal/dang-icon.png'
import NoMpAccountAvatar from '@/assets/auth-account-modal/no-mp-account-avatar.png'
import AmeIcon from '../AmeIcon/AmeIcon'
import { RcFile } from 'antd/es/upload'
import classNames from 'classnames'
// import { useOutsideClick } from '../ToolBar/components/utils'
import AmeDropdown from '../AmeDropdown/AmeDropdown'
import { uiController } from '@/controllers/uiController'
import AmeButton from '../AmeButton/AmeButton'
import { mpAccountContext } from '@/services/mpAccountService/mpAccountService'
import { message } from 'antd'
import { api } from '@/helpers/api'
import { observer } from 'mobx-react'


const getBase64 = (img: RcFile, callback: (data: string) => void) => {
  const reader = new FileReader();

  reader.addEventListener('load', async () => {
    callback(reader.result as string)
  });
  reader.readAsDataURL(img)
};

interface MpAccount {
  nickname: string,
  avatar: string
  appid: string
}

// const useAccountList = (open: boolean) => {
//   const [accountList, setAccountList] = useState<Account[]>([])
//   const [state, fetchAccountList] = useAsyncFn(async () => {
//     // 这里填充获取公众号列表的
//     const accountList: Account[] = await ([])
//     return accountList
//   }, [])

//   useEffect(() => {
//     if (state.value) {
//       setAccountList(state.value)
//     }
//   }, [state.value])

//   useMount(() => {
//     if (!open) { return }
//     fetchAccountList()
//   })
//   return accountList
// }
export interface SyncArticleModalProps {
  article: { title: string, desc: string, content: string } | null
  open: boolean
  mp?: MpAccount
}
const SyncArticleModal = observer(({ open, mp, article }: SyncArticleModalProps) => {
  const dropdownRef = useRef<{ hide: () => void } | null>(null)
  const [title, setTitle] = useState('')
  const [author, setAuthor] = useState('')
  const [desc, setDesc] = useState('')
  const [image, setImage] = useState<{ src: string, filename: string }>()
  const [account, setAccount] = useState<MpAccount | null>(null)
  const [isSyncing, setSyncing] = useToggle(false)
  const accountContext = useContext(mpAccountContext)

  useEffect(() => {
    setAccount(mp || null)
  }, [mp])

  useEffect(() => {
    if (article) {
      setTitle(article.title.slice(0, 64))
      setDesc(article.desc.slice(0, 54))
    } else {
      setTitle('')
      setDesc('')
    }
  }, [article])

  useMount(() => {
    if (!open) { return }
  })

  const onCancel = () => {
    clearModalCache()
    uiController.syncArticleModalProps = { open: false, article: null }
  }


  const beforeUpload = (file: RcFile) => {
    const isLt10M = file.size / (1024 * 1024) < 10
    if (!isLt10M) {
      message.warning(`图片大小超出${10}M限制，请修改后上传`)
      return false
    }
    getBase64(file, (url) => {
      setImage({ filename: file.name, src: url })
    })
    return false
  }
  const handleImgClear = (event: React.MouseEvent) => {
    event.stopPropagation()
    setImage(undefined)
  }
  const handleSelectAccount = (account: MpAccount) => {
    setAccount(account)
  }
  const handleAuthAccount = () => {
    uiController.showAuthAccountModalProps = {
      open: true,
      onClose: () => {
        uiController.showAuthAccountModalProps = { open: false }
      },
      onSuccess: (mp) => {
        uiController.showAuthAccountModalProps = { open: false }
        accountContext.updateAccountList()
        message.success('授权公众号成功')
        setAccount(mp)
      }
    }
  }

  const clearModalCache = () => {
    setAccount(null)
    setImage(undefined)
    setTitle('')
    setDesc('')
  }

  const genParam = () => {
    if (account === null) {
      return { msg: '请选择公众号', param: null }
    }
    if (!image) {
      return { msg: '请选择封面图', param: null }
    }
    if (!article) {
      return { msg: '文章异常,请刷新后重试', param: null }
    }
    return {
      msg: '',
      param: {
        appid: account.appid,
        title: title || '未命名文章',
        content: article.content,
        thumbMediaContent: image.src,
        filename: image.filename,
        author,
        digest: desc,
      }
    }
  }
  const onSyncArticle = async () => {
    if (isSyncing) {
      message.info('正在同步中...')
      return
    }
    const { msg, param } = genParam()
    if (msg) {
      message.warning(msg)
      return
    }
    setSyncing(true)
    if (param) {
      try {
        await api.mpAccount.syncArticleToWxDraft(param)
        message.success('同步文章成功')
        clearModalCache()
        uiController.syncArticleModalProps = { open: false, article: null }
        uiController.showSyncArticleSuccessModal = true
      } catch (error) {
        message.error(error.statusMessage)
      }
      setSyncing(false)
    }
  }

  return <AmeModal title="多平台同步" className={styles.syncArticleModal} width={756} open={open} okText="确定" cancelText="取消" footer={false} isCommonQrDialog onCancel={onCancel}>
    <div className={styles.modalWrapper}>
      <div className={styles.modalInner}>
        <div className={styles.desc}>
          <img src={DangIcon} alt="" />
          <span>可将文章内容一键同步至其他平台进行发布（目前支持微信公众平台）</span>
        </div>
        <p className={styles.title}>选择公众号：</p>
        <AmeDropdown ref={dropdownRef}>
          <AmeDropdown.Label>
            <div className={styles.accountLabel}>
              {!account && <div className={styles.placeholder}>
                <span>请选择公众号</span>
                <AmeIcon className={styles.downIcon} type="icon-line-global_down"></AmeIcon>
              </div>}
              {account && <div className={styles.account}>
                <span className={styles.accountInner}>
                  {account.avatar ? <img src={account.avatar} alt="" /> : <img src={NoMpAccountAvatar} alt="" />}
                  <span>{account.nickname}</span>
                </span>
                <AmeIcon className={styles.downIcon} type="icon-line-global_down"></AmeIcon>
              </div>}
            </div>
          </AmeDropdown.Label>
          <AmeDropdown.Menu>
            <div className={styles.accountDropdownPanel}>
              <ul className={styles.accountList}>
                {accountContext.mpList.filter(mp => mp.authorized).map((mp) => {
                  return <li className={styles.accountLi} key={mp.id} onClick={() => {
                    handleSelectAccount(mp)
                    dropdownRef.current && dropdownRef.current.hide()
                  }}>
                    {mp.avatar ? <img src={mp.avatar} alt="" /> : <img src={NoMpAccountAvatar} alt="" />}
                    <span>{mp.nickname}</span>
                  </li>
                })}
              </ul>
              <div className={styles.accountAddBtn} onClick={handleAuthAccount}>
                <AmeIcon className={styles.addBtnIcon} type="icon-line-global_add"></AmeIcon>
                <span>添加公众号</span>
              </div>
            </div>
          </AmeDropdown.Menu>
        </AmeDropdown>
        <p className={styles.subDesc}>将自动同步至该公众号的草稿箱</p>
        <p className={styles.title}>文章基础信息：</p>
        <div className={styles.articleSetting}>
          <div className={styles.setting}>
            <span className={styles.label}>标题：</span>
            <div className={styles.inputBox}>
              <FormInput placeholder='请输入' maxLength={64} value={title} onChange={(value) => { setTitle(value) }} ></FormInput>
            </div>
          </div>
          <div className={styles.setting}>
            <span className={styles.label}>作者：</span>
            <div className={styles.inputBox}>
              <FormInput placeholder='请输入' maxLength={8} inputType='small' value={author} onChange={(value) => { setAuthor(value) }}></FormInput>
            </div>
          </div>
          <div className={styles.setting}>
            <span className={classNames(styles.label, styles.required)}>封面：</span>
            <div className={styles.uploadBox}>
              <Upload className={styles.upload} showUploadList={false} beforeUpload={beforeUpload} accept='image/*'>
                <div className={styles.uploadBtn}>
                  {image && image.src && <AmeIcon type="icon-line-global_close21" className={styles.clearImgBtn} onClick={handleImgClear}></AmeIcon>}
                  {image && image.src && <img src={image.src} alt="" />}
                  {(!image || !image.src) && <AmeIcon className={styles.addBtn} type="icon-line-global_add"></AmeIcon>}
                </div>
              </Upload>
            </div>
          </div>
          <div className={styles.setting}>
            <span className={styles.label}>摘要：</span>
            <div className={styles.textareaBox}>
              <FormInput placeholder='若不填写默认为正文前54个字' maxLength={120} inputType="big" value={desc} onChange={(value) => { setDesc(value) }}></FormInput>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.btnBox}>
        <AmeButton className={styles.btn} onClick={onCancel}>取消</AmeButton>
        <AmeButton className={classNames(styles.btn, styles.confirmBtn)} loading={isSyncing} type='primary' onClick={onSyncArticle}>同步</AmeButton>
      </div>
    </div>
  </AmeModal>
})


export default SyncArticleModal