import { uiController } from '@/controllers/uiController'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { FC } from 'react'
import AmeModal from '../AmeModal/AmeModal'
import LoginModal from '../LoginModal/LoginModal'
import PayDialog from '../PayDialog/PayDialog'
import PaySuccessDialog from '../PaySuccessDialog/PaySuccessDialog'
import styles from './CommonDialogLayout.module.less'
import QrBg from '@/assets/pay-dialog/bg__qr.png'
import StaffQr from '@/assets/qr__staff.png'
import TipsIcon from '@/assets/pay-dialog/icon__tips.png'
import BgFreeWord from '@/assets/bg__free-word.png'
import GiftIcon from '@/assets/gift.png'
import AmeButton from '../AmeButton/AmeButton'
import { userService } from '@/services/userService'
import AuthAccountModal from '../AuthAccountModal/AuthAccountModal'
import AuthAccountEmptyModal from '../AuthAccountEmptyModal/AuthAccountEmptyModal'
import SyncArticleSuccessModal from '../SyncArticleSuccessModal/SyncArticleSuccessModal'
import SyncArticleModal from '../SyncArticleModal/SyncArticleModal'
import ShareArticleLinkModal from '../ShareArticleLinkModal/ShareArticleLinkModal'
interface CommonDialogLayoutProps { }

const CommonDialogLayout: FC<CommonDialogLayoutProps> = observer(
  (props: CommonDialogLayoutProps) => {
    return (
      <div>
        {/*  分享文章的复制链接弹窗 */}
        <ShareArticleLinkModal {...uiController.shareArticleLinkModalProps}></ShareArticleLinkModal>
        {/* 同步公众号文章弹窗 */}
        <SyncArticleModal {...uiController.syncArticleModalProps}></SyncArticleModal>
        {/* 同步公众号文章成功弹窗 */}
        <SyncArticleSuccessModal open={uiController.showSyncArticleSuccessModal}></SyncArticleSuccessModal>
        {/* 授权公众号弹窗 */}
        <AuthAccountEmptyModal {...uiController.emptyAccountGuideModalProps}></AuthAccountEmptyModal>
        <AuthAccountModal {...uiController.showAuthAccountModalProps}></AuthAccountModal>
        {/* 登录弹窗 */}
        <LoginModal open={uiController.showLoginModal}></LoginModal>
        {/* 通用提示弹窗 */}
        <AmeModal {...uiController.commonModalProps}></AmeModal>
        {/* 支付弹窗 */}
        <PayDialog {...uiController.payDialogProps}></PayDialog>
        {/* 支付成功弹窗 */}
        <PaySuccessDialog
          open={uiController.showPaySuccessDialog}
        ></PaySuccessDialog>

        {/* 联系客服弹窗 */}
        <AmeModal
          width="509px"
          className={classNames(styles.contactDialog)}
          open={uiController.showContactUsDialog}
          title="扫码添加客服"
          isCommonQrDialog
          footer={false}
          onCancel={() => {
            uiController.showContactUsDialog = false
          }}
        >
          <p className={classNames(styles.desc)}>您的专属客服，即将为您服务</p>
          <div className={styles.qrBox}>
            <img src={QrBg} alt="" className={classNames(styles.bg)} />
            <img src={StaffQr} alt="" className={classNames(styles.qr)} />
          </div>
        </AmeModal>

        {/* 字数不足弹窗 */}
        <AmeModal
          width="509px"
          className={classNames(styles.noEnoughTokenDialog)}
          open={uiController.showNoEnoughTokenDialog}
          title="可用字数不足"
          isCommonQrDialog
          footer={false}
          onCancel={() => {
            uiController.showNoEnoughTokenDialog = false
          }}
        >
          <div className={classNames(styles.noFreeWordTips)}>
            <p>
              <img src={TipsIcon} alt="" />
              您本月已达输出上线5W字，如您有更多使用需求，请扫码添加客服，为您解决
            </p>
          </div>
          <div className={styles.qrBox}>
            <img src={QrBg} alt="" className={classNames(styles.bg)} />
            <img src={StaffQr} alt="" className={classNames(styles.qr)} />
          </div>
        </AmeModal>

        {/* 免费赠送字数弹窗，仅首次注册弹 */}
        <AmeModal
          width="488px"
          className={classNames(styles.freeWordDialog)}
          open={uiController.showFreeWordDialog}
          footer={false}
          isCommonQrDialog
          onCancel={() => {
            uiController.showFreeWordDialog = false
          }}
        >
          <img src={BgFreeWord} alt="" className={styles.bg} />
          <div className={styles.title}>
            <img src={GiftIcon} alt="" />
            欢迎使用灵感快写
          </div>
          <p className={classNames(styles.desc)}>
            我们为你准备了5,000字的使用额度，方便前期了解产品
          </p>
          <AmeButton
            style={{ width: '252px' }}
            type="primary"
            size="large"
            onClick={() => {
              uiController.showFreeWordDialog = false
              if (!userService.isLogin) {
                uiController.showLoginModal = true
              }
            }}
          >
            立即体验
          </AmeButton>
        </AmeModal>
      </div>
    )
  }
)

export default CommonDialogLayout
