import { observer } from 'mobx-react'
import styles from './SyncArticleSuccessModal.module.less'
import { useMount } from 'react-use'
import AmeModal from '../AmeModal/AmeModal'
import classNames from 'classnames'
import img from '@/assets/auth-account-modal/sync-success.png'
import AmeButton from '../AmeButton/AmeButton'
import { uiController } from '@/controllers/uiController'

const SyncArticleSuccessModal = observer(({ open }: { open: boolean }) => {
  useMount(() => {
    if (!open) return
  })
  const onCancel = () => {
    uiController.showSyncArticleSuccessModal = false
  }
  return <AmeModal title="同步成功" isCommonQrDialog open={open} footer={false} width={509} onCancel={onCancel}>
    <img className={styles.syncImg} src={img} alt="" />
    <p className={styles.desc}>同步成功，去草稿箱发布文章吧～</p>
    <div className={styles.btnBox}>
      <AmeButton className={styles.btn} onClick={onCancel}>取消</AmeButton>
      <AmeButton className={classNames(styles.btn, styles.confirmBtn)} type='primary' onClick={() => {
        window.open('https://mp.weixin.qq.com', '_blank')
      }}>去微信公众平台</AmeButton>
    </div>
  </AmeModal>
})


export default SyncArticleSuccessModal