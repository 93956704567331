import { uiController } from '@/controllers/uiController'
import { api } from '@/helpers/api'
import { commonUtils } from '@/helpers/commonUtils'
import { storageHelper } from '@/helpers/storageHelper'
import { computed, makeObservable, observable, runInAction } from 'mobx'

class UserService {
  @observable userData: ServerData.UserData | null = null

  @computed get isLogin() {
    return !!this.userData
  }

  @computed get remainCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      config: { version, monthConfig },
    } = this.userData!
    return version === 'free' ? monthConfig.freeAiCount : monthConfig.proAiCount
  }

  constructor() {
    makeObservable(this)
  }

  public async init() {
    this.loadCacheData()
    this.updateUserInfo()
  }

  public updateSEMKey() {
    try {
      const { utm, kword, bd_vid, bd_url } = commonUtils.getUrlArgs()
      if (utm) {
        storageHelper.set({ utm })
        // localStorage.setItem(STORAGE_KEYS.utm, utm)
      }
      if (
        document.referrer &&
        document.referrer.indexOf('kuaixie') === -1 &&
        document.referrer.indexOf('localhost') === -1
      ) {
        if (!storageHelper.get(['referer']).referer) {
          storageHelper.set({ referer: document.referrer })
        }
      }
      if (kword) {
        const decodedKword = decodeFromGb2312(kword)
        storageHelper.set({ kword: decodedKword })
      }

      // 并不意味着 location.href 中有 bd_vid 就是从百度投放来的
      // 还有可能是从自定义投放页面来的 此时会携带 bd_url
      if (bd_vid || bd_url) {
        // 优先 bd_url 其次 location.href
        const bd_url_vid = commonUtils.getUrlParams(null, bd_url || 'bd_url').bd_vid
        if (bd_url && bd_url_vid) {
          storageHelper.set({ bdVid: bd_url_vid })
          storageHelper.set({ bdUrl: bd_url })
        } else {
          storageHelper.set({ bdVid: bd_vid })
          // eslint-disable-next-line no-restricted-globals
          storageHelper.set({ bdUrl: location.href })
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  public async updateUserInfo() {
    try {
      const { data: userData } = await api.user.getUserInfo()
      console.log('get user info: ', userData)
      runInAction(() => {
        this.userData = userData
      })
      storageHelper.set({ userData })
      if (userData) {
        storageHelper.set({ receivedFreeWordStatus: 'receive' })
        if (!userData.config.active) {
          try {
            api.user.active()
          } catch (error) { }
        }
      }
    } catch (error) {
      runInAction(() => {
        this.userData = null
      })
      storageHelper.set({ userData: null })
    }
  }

  public async logout() {
    try {
      await api.user.logout()
      this.userData = null
      storageHelper.set({ userData: null })
      uiController.message.success('退出成功')
    } catch (error) {
      uiController.message.error(
        `操作失败：${commonUtils.formatErrorMsg(error)}`
      )
    }
  }

  private loadCacheData() {
    const { userData } = storageHelper.get(['userData'])
    this.userData = userData
  }
}

export const userService = new UserService()
