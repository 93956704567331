import React, { Children, forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import styles from './AmeDropdown.module.less'
import classNames from 'classnames'
import { useClickAway, useToggle } from 'react-use'

const AME_DROPDOWN_LABEL = 'AmeDropdownLabel'
const AME_DROPDOWN_MENU = 'AmeDropdownMenu'



interface LabelProps {
  children: React.ReactNode
}
const AmeDropdownLabel = ({ children, }: LabelProps) => {
  return <div className={classNames(styles.dropdownLabel)}>{children}</div>
}
AmeDropdownLabel.displayName = AME_DROPDOWN_LABEL

interface MenuProps {
  children: React.ReactNode
}

const AmeDropdownMenu = ({ children, }: MenuProps) => {
  return <div className={classNames(styles.dropdownMenu)}>{children}</div>
}

AmeDropdownMenu.displayName = AME_DROPDOWN_MENU

interface ComponentChildren extends React.ReactElement {
  RcName: string
}
interface Props {
  children: React.ReactNode
}


const AmeDropdown = forwardRef(({ children, }: Props, ref) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isClickedOutside, clickOutside] = useToggle(true)
  const childrenArray = Children.toArray(children) as unknown as ComponentChildren[]
  // console.log('childrenArray', childrenArray)
  const labelCom = childrenArray.find(child => typeof child.type !== 'string' && (child.type as any).displayName === AME_DROPDOWN_LABEL)
  const menuCom = childrenArray.find(child => typeof child.type !== 'string' && (child.type as any).displayName === AME_DROPDOWN_MENU)
  useClickAway(dropdownRef, () => {
    clickOutside(true)
  })

  useImperativeHandle(ref, () => {
    return {
      hide: () => {
        setTimeout(() => {
          clickOutside(true)
        });
      }
    }
  }, [])

  return <div ref={dropdownRef} className={classNames(styles.dropdownBox)} onClick={() => clickOutside(false)}>
    <div className={classNames(styles.dropdownLabel)}>
      {labelCom?.props.children}
    </div>
    <div className={classNames(styles.dropdownMenu, isClickedOutside ? styles.hide : '')}>
      {menuCom?.props.children}
    </div>
  </div>
})

interface CompoundedComponent extends React.ForwardRefExoticComponent<Props & React.RefAttributes<{ hide: () => void }>> {
  Label: typeof AmeDropdownLabel;
  Menu: typeof AmeDropdownMenu;
}
// declare const Dropdown: CompoundedComponent;
// export { Button, Group };
// export default Radio;
// AmeDropdown.Menu = AmeDropdownMenu
// AmeDropdown.Label = AmeDropdownLabel

// export { Menu: AmeDropdownMenu, Label: AmeDropdownLabel }
const Compounded = AmeDropdown as CompoundedComponent
Compounded.Label = AmeDropdownLabel
Compounded.Menu = AmeDropdownMenu
// export { AmeDropdownLabel, AmeDropdownMenu }
export default Compounded