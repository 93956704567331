import { Modal, ModalProps } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC } from 'react'
import styles from './AmeModal.module.less'

export interface AmeModalProps extends ModalProps {
  content?: string
  isCommonQrDialog?: boolean
}

const AmeModal: FC<AmeModalProps> = observer((props: AmeModalProps) => {
  const { className, content, isCommonQrDialog } = props
  return (
    <Modal
      {...props}
      maskClosable={false}
      centered
      className={classNames(className, styles.ameModal, {
        [styles.commonQrDialog]: isCommonQrDialog,
      })}
    >
      {content}
      {props.children}
    </Modal>
  )
})

export default AmeModal
