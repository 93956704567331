import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { createFromIconfontCN } from '@ant-design/icons'
import { ICONFONT_SCRIPT_URL } from '@/helpers/commonConsts'
import classNames from 'classnames'
interface AmeIconProps {
  type: string
  className?: string
  onClick?: (e: any) => void
  style?: React.CSSProperties
}

const AmeIcon: FC<AmeIconProps> = observer((props: AmeIconProps) => {
  const { type, className, onClick } = props
  const Iconfont = createFromIconfontCN({ scriptUrl: ICONFONT_SCRIPT_URL })
  return (
    <Iconfont
      style={props.style}
      className={classNames('ame-icon', className)}
      type={type}
      onClick={(e) => {
        if (onClick) {
          onClick(e)
        }
      }}
    ></Iconfont>
  )
})

export default AmeIcon
