import dayjs from 'dayjs'
import zh from 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)
dayjs.locale(zh)

class DayjsHelper {
  public fromNow(date: string | number) {
    const format = 'YYYY-MM-DD'
    const now = dayjs()
    const diff = now.diff(dayjs(date), 'day')

    if (diff > 7) {
      return dayjs(dayjs(date)).format(format)
    }

    return dayjs(dayjs(date)).fromNow()
  }

  public formatTime(date: string | number, template = 'YYYY-MM-DD') {
    // Aug 16, 2018 12:00
    return dayjs(date).format(template)
  }
}

export const dayjsHelper = new DayjsHelper()
