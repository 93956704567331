import { Editor, Transforms, Node, Range, } from 'slate'
import { deserialize } from '../deserialize'
import { BLOCK_PARAGRAPH } from '../type'


const processCloseTag = (htmlStr: string) => {
  const str = htmlStr
    /** 处理 br */
    .replace(/<br\s*([^>]*)>/g, '<br$1/>')
  /** 处理 img */
  // .replace(/<img\s*([^>]*)>/g, '<img$1/>')

  return str
}
const processFragmentTag = (htmlStr: string) => {
  const str = htmlStr.replace('<!--StartFragment-->', '')
    .replace('<!--EndFragment-->', '')
    .replace('<body>', '<body><!--StartFragment-->')
    .replace('</body>', '<!--EndFragment--></body>')
  return str
}

export const htmlToEditorChildrenNode = (htmlStr: string, source = 'init'): Node[] => {
  if (!htmlStr) { return [] }
  /** 处理没有自闭合的标签 */
  const newHTMLStr = processFragmentTag(processCloseTag(htmlStr))
  const html = newHTMLStr.replaceAll(/\\n+$/g, " ").trim()
  const parsed = new DOMParser().parseFromString(html, 'text/html')
  // console.log('boyd', parsed.body)
  const nodes = deserialize(parsed.body) as Node[]
  // console.log('nodes', nodes)
  return nodes
}

export const withHtml = (editor: Editor) => {
  const { insertData, insertBreak, deleteFragment, deleteBackward } = editor
  editor.insertData = data => {
    const html = data.getData('text/html')
    if (html) {
      const fragment = htmlToEditorChildrenNode(html)
      Transforms.insertFragment(editor, fragment)
      return
    }
    insertData(data)
  }

  editor.deleteFragment = (data) => {
    if (!editor.selection) { return }
    const [, startPath] = editor.first([0])
    const endPath = editor.end([])
    const fullRange = editor.range(startPath, endPath)
    /** 仅针对全文的删除逻辑 */
    if (Range.equals(fullRange, editor.selection)) {
      editor.removeNodes({ mode: 'highest', hanging: true })
      if (editor.children.length === 0) {
        /** 删完需要插入一个空行 */
        editor.insertNode({
          type: BLOCK_PARAGRAPH,
          children: [{ text: '' }]
        })
      }
    } else {
      deleteFragment()
    }
  }

  editor.deleteBackward = (data) => {
    deleteBackward(data)
  }

  editor.insertBreak = () => {
    insertBreak()
  }

  // editor.insertText = (data) => {
  //   // console.log(Editor.marks(editor))
  //   // 在这里设置默认的样式，样式从 editor.marks 上获取
  //   editor.insertNode({ text: data, ...Editor.marks(editor) })
  // }

  return editor
}
