import { user } from './user'
import { aiTask } from './aiTask'
import { template } from './template'
import { article } from './article'
import { mpAccount } from './mpAccount'
import { styles } from './styles'
import { tool } from './tool'

export const api = {
  user,
  aiTask,
  template,
  article,
  mpAccount,
  styles,
  tool,
}
