import { makeObservable, observable, runInAction } from 'mobx';
import { userService } from '../userService';
import { storageHelper } from '@/helpers/storageHelper';
import { api } from '@/helpers/api';
import { htmlToEditorChildrenNode } from '@/components/Editor/helper/plugins/serialize-html';
import { Node } from 'slate';
import _, { } from 'lodash';
import { createContext } from 'react';
import { ee } from '../eventEmitter/eventEmitter';



interface Article {
  id: string
  desc: string
  title: string
  content: string
  userId: string
}

const DEFAULT_ARTICLE: Article = {
  id: '',
  desc: '',
  title: '',
  content: '',
  userId: ''
}



class SavingService {
  @observable saving: boolean = false
  @observable article: Article | null = null

  constructor() {
    makeObservable(this)
  }
  public init() { }

  public savingCacheArticle() {
    const article = this.getStorageArticle()
    if (article) {
      this.savingArticleDe(article)
    }
  }

  public savingArticleDe = this.savingArticle
  // _.debounce(this.savingArticle, 100)

  private async savingArticle(article: Partial<Article>) {
    this.setSaving(true)
    const userInfo = userService.userData
    const userId = userInfo ? userInfo.id : ''
    let storageArticle = this.getStorageArticle()
    if (!storageArticle || (storageArticle.userId && storageArticle.userId !== userId)) {
      storageArticle = { ...DEFAULT_ARTICLE }
    }
    const mergedArticle = {
      ...storageArticle,
      ...article,
      desc: this.genArticleDesc(article.content || ''),
      userId
    }
    mergedArticle.desc = this.genArticleDesc(mergedArticle.content)
    this.setStorageArticle(mergedArticle)
    ee.emit('updateArticle', mergedArticle)
    this.setArticle(mergedArticle)
    if (userId) {
      await this.updateBackEnd(mergedArticle)
    }
    setTimeout(() => {
      this.setSaving(false)
    })
  }

  public getStorageArticle(): Article | null {
    return storageHelper.get(['savedArticle']).savedArticle
  }

  private setStorageArticle(article: Article) {
    storageHelper.set({ savedArticle: article })
  }


  private updateBackEnd = _.debounce(async (mergedArticle: Article) => {
    if (mergedArticle.id) {
      await this.handleUpdate(mergedArticle)
    } else {
      await this.handleCreate(mergedArticle)
    }
  }, 500, { maxWait: 1500 })

  private async updateArticle(article: Article): Promise<{ succeed: boolean, statusCode: number }> {
    try {
      const { statusCode } = await api.article.update({
        articleId: article.id,
        article: {
          title: article.title,
          desc: article.desc,
          content: article.content,
          saveAction: 'auto',
        }
      })
      return { statusCode, succeed: true }
    } catch (error) {
      return { statusCode: error.statusCode, succeed: false }
    }
  }

  private async createArticle(article: Article): Promise<Article | null> {
    try {
      const { data: { id } } = await api.article.add({
        title: article.title,
        desc: article.desc,
        content: article.content,
        saveAction: 'auto',
      })
      return {
        ...article, id
      }
    } catch (error) {
      return null
    }
  }

  private genArticleDesc(content: string): string {
    const editorNodes = htmlToEditorChildrenNode(content, 'save')
    const articleText = editorNodes.map((n) => { return Node.string(n) }).join('\n')
    return articleText.slice(0, 50)
  }

  private async handleUpdate(article: Article) {
    // console.log('update', article)
    const { statusCode, succeed } = await this.updateArticle(article)
    if (!succeed) {
      if (statusCode >= 400 && statusCode < 500) {
        /** 400 报错更新失败，是这个文章的 ID 不合法，所以这里重置一下，下一次会走创建 */
        // this.setStorageArticle({ ...article, id: '' })
        // this.setArticle({ ...article, id: '' })
        this.handleCreate({ ...article, id: '' })
      }
      // message.warning('文章更新失败')
    } else {
      this.setArticle({ ...article })
    }
  }

  private async handleCreate(article: Article) {
    const createdArticle = await this.createArticle(article)
    if (createdArticle) {
      this.setStorageArticle(createdArticle)
      this.setArticle({ ...createdArticle })
    } else {
      this.setArticle(null)
    }
  }


  private setArticle(article: Article | null) {
    runInAction(() => {
      this.article = article
    })
  }

  private setSaving(saving: boolean) {
    runInAction(() => {
      this.saving = saving
    })
  }

}


export const savingService = new SavingService()
export const savingContext = createContext(savingService)